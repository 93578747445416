<template>
  <div class="sign-success-page">
    <div>
      <div class="icon">
        <div class="title">签署完成</div>
        <SvgIcon type="launch_success" class="launch_success" />
      </div>
      <div class="button-group" v-if="callBack">
        <van-button type="primary">{{ s }}秒后跳转</van-button>
      </div>
      <div class="button-group" id="launch-btn" v-else-if="wxCallBack"></div>
      <div class="button-group" v-else>
        <!-- <van-button type="primary" plain @click="close">关闭</van-button> -->
        <van-button type="primary" @click="goPreview">查看文件</van-button>
      </div>
      <div class="button-group" v-if="wxCallBack">
        <van-button type="primary" @click="goBackMini" v-if="mini"
          >返回小程序</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
  import sassApi from '@/api/sass'

  export default {
    data() {
      return {
        s: 3,
        time: null,
        callBack: '',
        appKey: '',
        contractNum: '',
        telephone: '',
        secret: '',
        timestamp: '',
        v: '',
        wxCallBack: '',
        html: '',
        mini: false, // 在小程序中
      }
    },
    created() {
      const {
        callBack,
        appKey,
        contractNum,
        telephone,
        secret,
        timestamp,
        v,
        wxCallBack,
      } = this.$route.query
      this.callBack = callBack
      this.appKey = appKey
      this.contractNum = contractNum
      this.telephone = telephone
      this.secret = secret
      this.timestamp = timestamp
      this.v = v
      if (wxCallBack) {
        this.wxCallBack = JSON.parse(wxCallBack)
      }
      if (window.sessionStorage.Refresh) {
        sessionStorage.removeItem('Refresh')
        window.location.reload()
        return
      }
      if (this.wxCallBack && !this.mini) {
        this.signConfig()
      }
      if (this.callBack) {
        this.time = setInterval(() => {
          this.s -= 1
          if (this.s <= 0) {
            clearInterval(this.time)
            window.location.href = decodeURIComponent(this.callBack)
          }
        }, 1000)
      }
      window.wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          this.mini = true
        } else {
          this.type = false
        }
      })
    },
    mounted() {
      if (this.wxCallBack && !this.mini) {
        this.wx_launch()
      }
    },
    methods: {
      goBackMini() {
        window.wx.miniProgram.navigateBack()
      },
      wx_launch() {
        const content = `
        <button class="test-btn">跳转小程序</button>
        <style>
          .test-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 44px;
            background: #1676ff;
            border-radius: 4px;
            color:#fff;
            font-size:16px;
            border:none;
          }
        </style>
        `
        const btn = document.getElementById('launch-btn') // 获取元素
        const script = document.createElement('script') // 创建script内容插槽 避免template标签冲突
        script.type = 'text/wxtag-template' // 使用script插槽 必须定义这个type
        script.text = content // 自定义的html字符串内容
        const html = `<wx-open-launch-weapp style="width:100%;display:block;" username="${this.wxCallBack.username}" path="${this.wxCallBack.path}">${script.outerHTML}</wx-open-launch-weapp>`
        btn.innerHTML = html // html字符串赋值
        // 点击按钮 正常跳转触发
        btn.addEventListener('launch', function(e) {
          console.log('success')
        })
        // 点击跳转 抛出异常
        btn.addEventListener('error', function(e) {
          console.log('fail', e.detail)
          alert(`跳转异常 - ${e.detail}`)
        })
      },
      signConfig() {
        sassApi
          .signConfig({})
          .then(res => {
            console.log(res)
            this.initWxConfig(res)
          })
          .catch(err => {
            console.log(err)
          })
      },
      initWxConfig(data) {
        window.wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ['checkJsApi'],
          openTagList: ['wx-open-launch-weapp'],
        })
        window.wx.ready(() => {})
        window.wx.error(res => {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log(res)
        })
      },
      goPreview() {
        this.$router.push({
          path: '/signFilePreview',
          query: {
            appKey: this.appKey,
            contractNum: this.contractNum,
            telephone: this.telephone,
            secret: this.secret,
            timestamp: this.timestamp,
            v: this.v,
          },
        })
      },
      close() {
        window.location.href = 'about:blank'
        window.close()
      },
      handleLaunchFn(e) {
        console.log(e)
      },
      handleErrorFn(e) {
        console.log('fail', e.detail)
      },
    },
  }
</script>

<style lang="less" scoped>
  .sign-success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .icon {
      margin-top: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .launch_success {
        font-size: 96px;
      }
      .title {
        font-size: 16px;
        color: #111a34;
        font-weight: bold;
        line-height: 24px;
      }
      .text {
        margin-top: 4px;
        color: #677283;
        font-size: 14px;
        font-weight: 500;
      }
      .tip {
        margin-top: 32px;
        font-size: 14px;
        color: #111a34;
      }
    }

    .button-group {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      width: 280px;
      .van-button {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
      }
      .van-button + .van-button {
        margin-top: 24px;
      }
    }
    .followForm {
      width: 100%;
      height: 155px;
      background: #f4f5f6;
      padding: 27px 47px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .code {
        width: 101px;
        height: 101px;
        img {
          width: 100%;
        }
      }
      .detail {
        width: 144px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #1f2023;
        line-height: 27px;
      }
    }
  }
</style>
